(function(){
  let accordionBtns = document.querySelectorAll(".js-category-item-trigger");
  let accordionBtnOpenClass = 'is-active';
  let accordionBtnTransitionendClass = 'is-ended';
  let categoryItem = document.querySelectorAll(".category-item");
  const term = 300; 
  let timer = 0; 

  let inertFn = function() {
    if (window.matchMedia('(max-width: 759.98px)').matches) {
      categoryItem.forEach((element)=>{
        if(element.classList.contains("is-active")){
          element.getElementsByClassName("category-list-wp")[0].inert = false;
        }else{
          element.getElementsByClassName("category-list-wp")[0].inert = true;
        }
      });
      accordionBtns.forEach((element)=>{
        element.role="button";
      });
    } else {
      categoryItem.forEach((element)=>{
        element.getElementsByClassName("category-list-wp")[0].inert = false;
      });
      accordionBtns.forEach((element)=>{
        element.role="";
      });
    }
  }
  let clickFn = function(btn,target_inert) {
    if( btn.classList.contains(accordionBtnOpenClass) ){
      btn.classList.remove(accordionBtnOpenClass);
      btn.classList.remove(accordionBtnTransitionendClass);
      target_inert.inert = true;
    }else{
      btn.classList.add(accordionBtnOpenClass);
      target_inert.inert = false;
    }
    let transition = event.currentTarget.nextElementSibling
    transition.addEventListener("transitionend", () => {
      if(btn.classList.contains("is-active") == true){
        btn.classList.add(accordionBtnTransitionendClass);
      }
    });
  }
  inertFn();
  window.addEventListener("resize", function(){
    clearTimeout(timer);
    timer = setTimeout(function(){
      inertFn();
    }, term);
  });
  accordionBtns.forEach((element)=>{
    element.addEventListener('keydown',(event)=>{
      if (event.key == "Enter") {
        let btn = event.currentTarget.parentNode;
        let target_inert = event.currentTarget.parentNode.getElementsByClassName("category-list-wp")[0];
        clickFn(btn,target_inert);
      }
    });
    element.addEventListener('click',(event)=>{
      let btn = event.currentTarget.parentNode;
      let target_inert = event.currentTarget.parentNode.getElementsByClassName("category-list-wp")[0];
      clickFn(btn,target_inert);
    });
  });
})();